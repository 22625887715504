<template>
  <div class="state">
    <div class="img Mbm30">
      <slot name="img"> </slot>
    </div>
    <div class="Mbm20 F14">
      <slot name="text"> </slot>
    </div>
    <div class="Pbm85">
      <slot name="state"> </slot>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.state {
  text-align: center;
}
</style>