<template>
  <div id="phomeVerify">
    <pwd-contair>
      <div slot="menu">密码重置</div>
      <div slot="pwdContair">
        <div>
          <step-contair :active="0"></step-contair>
          <div>
            <change-state>
              <div slot="img">
                <img src="../../../../assets/supplier/yanzheng.png" />
              </div>
              <div slot="text">
                <div class="F14">为确认是您本人操作，请选择身份认证</div>
              </div>
              <div slot="state">
                <el-button
                  class="Ptp15 Pbm15 Plf80 Prt80"
                  @click="getVerify()"
                  >使用手机短信验证码</el-button
                >
              </div>
            </change-state>
          </div>
        </div>
      </div>
    </pwd-contair>
    <!-- 手机验证弹窗 -->
    <!-- <el-dialog :visible.sync="verifyDialog" width="520px">
      <div class="Ptp30 Pbm120 Plf80 Prt80 tan">
        <img src="../../../../assets/supplier/phone-verfiy.png" />
        <div class="Mtp15">使用手机短信验证码</div>
        <div class="Mtp40 yanzheng co_7474">
          <span>当前手机号<span>1111</span></span>
          <div class="verfiyNum Mtp20">
            <el-row :gutter="5">
              <el-col :span="14">
                <el-input
                  v-model="input"
                  placeholder="请输入手机验证码"
                ></el-input>
              </el-col>
              <el-col :span="10">
                <el-button type="primary"
                  >获取验证码<strong>|</strong><span class="F14">59s</span></el-button
                >
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="Mtp100">
           <el-button type="primary" @click="submit()">提交认证</el-button>
        </div>
      </div>

    </el-dialog> -->
  </div>
</template>
<script>
import PwdContair from "@/components/supplier/pwdContair/pwdContair.vue";
import StepContair from "@/components/supplier/pwdContair/stepContair.vue";
import ChangeState from "@/components/supplier/pwdContair/changeState.vue";
export default {
  components: { PwdContair, StepContair, ChangeState },
  name: "PhomeVerify",
  data() {
    return {
      verifyDialog: false,
    };
  },
  methods: {
    getVerify() {
      this.$router.push("/supplier/supplierHome/getVerfiy");
    }
  }
  
};
</script>
<style lang="scss" scoped>
.tan {
  text-align: center;
  .yanzheng {
    text-align: left;
  }
  .el-button {
    width: 100%;
  }
}
</style>
