<template>
  <div>
    <div class="step">
      <el-steps :active="active" align-center>
        <el-step title="身份认证"></el-step>
        <el-step title="设置操作"></el-step>
        <el-step title="完成"></el-step>
      </el-steps>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    active: {
      type: Number,
      default: 0
    }
  }, 
};
</script>
<style lang="scss" scoped>
.step {
  padding: 10px 200px 50px 200px;
}
</style>