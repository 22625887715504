import { render, staticRenderFns } from "./PhoneVerfiy.vue?vue&type=template&id=337404ee&scoped=true&"
import script from "./PhoneVerfiy.vue?vue&type=script&lang=js&"
export * from "./PhoneVerfiy.vue?vue&type=script&lang=js&"
import style0 from "./PhoneVerfiy.vue?vue&type=style&index=0&id=337404ee&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "337404ee",
  null
  
)

export default component.exports